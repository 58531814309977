









































































































































































































































































import Vue from 'vue'
import {
  BCard, BTable, BBadge, BButton, BCol, BRow, BForm, BFormInput,
  BFormGroup, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import { appSettingsCommunication } from '@/store/modules/app-settings-communication'
import EmailEventItemModalVue from './EmailEventItemModal.vue'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import { permissionSubjects } from '@/libs/acl/constants'

let debounceTimeout: ReturnType<typeof setTimeout> = setTimeout(() => '', 1000)

export default Vue.extend({
  name: 'SettingsCommunicationList',
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BTable,
    BBadge,
    BButton,
    BCol,
    BRow,
    BForm,
    BFormInput,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,

    TableSpinner,
    EmailEventItemModalVue,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentRouteName: this.$router.currentRoute.name,
      required,
      email,
      permissionSubjects,
    }
  },
  computed: {
    tableColumns() {
      return appSettingsCommunication.getters.getTableColumns
    },
    perPage() {
      return appSettingsCommunication.getters.getPerPage
    },
    totalItems() {
      return appSettingsCommunication.getters.getTotalItems
    },
    currentPage: {
      get() {
        return appSettingsCommunication.getters.getCurrentPage
      },
      set(val: number) {
        appSettingsCommunication.mutations.SET_CURRENT_PAGE(val)
      },
    },
    perPageOptions() {
      return appSettingsCommunication.getters.getPerPageOptions
    },
    searchQuery: {
      get() {
        return appSettingsCommunication.getters.getSearchQuery
      },
      set(val: string) {
        appSettingsCommunication.mutations.SET_SEARCH_QUERY(val)
      },
    },
    dataMeta() {
      return appSettingsCommunication.getters.getDataMeta
    },
    settings() {
      return appSettingsCommunication.getters.getCommunicationSettings
    },
  },
  watch: {
    $route() {
      if (!this.$route.query.currentPage) {
        this.setFilterParams()
      }
      this.updateTable()
    },
    currentPage(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'currentPage', val)
      }
    },
    perPage(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'perPage', val)
      }
    },
    searchQuery(val, prevVal) {
      if (val !== prevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(this, this.currentRouteName, 'searchQuery', val)
        }, 250)
      }
    },
  },
  async created() {
    await this.prepareMailSettings()
    await this.prepareEmailTemplates()
    await this.getRolesAndEventsForCommunicationSettings()

    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
  },
  methods: {
    async getRolesAndEventsForCommunicationSettings() {
      await appSettingsCommunication.actions.fetchRolesList()
      await appSettingsCommunication.actions.fetchEventsList()
    },
    async prepareMailSettings() {
      await appSettingsCommunication.actions.prepareMailSettings()
    },
    async prepareEmailTemplates() {
      await appSettingsCommunication.actions.fetchEmailTemplatesList()
    },
    async fetchMailByEvents() {
      return appSettingsCommunication.actions.fetchMailByEvents()
    },
    saveCommunicationSettings() {
      return new Promise((resolve, reject) => {
        (this.$refs.settingsRules as InstanceType<typeof ValidationObserver>).validate().then(success => {
          if (success) {
            resolve(true)
            this.saveMailSettings()
          } else {
            window.scrollTo(0, 0)
            reject()
          }
        })
      })
    },
    async saveMailSettings() {
      const preparedData = {
        id: this.settings.id,
        communication_settings_id: this.settings.communication.id,
        communication_settings_from_name: this.settings.communication.name,
        communication_settings_from_email: this.settings.communication.email,
      }
      if (this.settings.id) {
        await appSettingsCommunication.actions.updateSetting(preparedData)
      } else {
        await appSettingsCommunication.actions.postSetting(preparedData)
      }
    },
    addEmailEvent() {
      appSettingsCommunication.mutations.RESET_EMAIL_EVENT()
      this.$bvModal.show('email-event-modal')
    },
    editItem(item: any) {
      const editMailEvent = {
        id: item.id,
        role_id: item.role ? item.role.id : null,
        email_template_id: item.emailTemplate?.id,
        user_event_id: item.userEvent?.id,
      }
      appSettingsCommunication.mutations.SET_EMAIL_EVENT(editMailEvent)
      this.$bvModal.show('email-event-modal')
    },
    async deleteItem(id:string) {
      await appSettingsCommunication.actions.deleteCommunicationItem(id)
      this.updateTable()
    },
    updateTable() {
      (this.$refs.refMailsByEventsListTable as BTable).refresh()
    },
    updatePerPage(val: number) {
      appSettingsCommunication.mutations.SET_PER_PAGE(val)
    },
    setFilterParams() {
      appSettingsCommunication.mutations.SET_FILTER_PARAMS()
    },
  },
})
