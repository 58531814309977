


















































































import Vue from 'vue'
import {
  BFormSelect, BCol, BRow, BFormGroup, BvModalEvent,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { appSettingsCommunication } from '@/store/modules/app-settings-communication'

export default Vue.extend({
  name: 'EmailEventItemModal',
  components: {
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    BFormGroup,
    BFormSelect,
  },
  computed: {
    emailEvent() {
      return appSettingsCommunication.getters.getEmailEvent
    },
    eventsList() {
      return appSettingsCommunication.getters.getEventsList
    },
    rolesList() {
      return appSettingsCommunication.getters.getRolesList
    },
    emailTemplatesList() {
      return appSettingsCommunication.getters.getEmailTemplatesList
    },
    title(): string {
      return this.emailEvent.id ? 'Edit Email on Event' : 'Add Email on Event'
    },
    okTitle(): string {
      return this.emailEvent.id ? 'Save' : 'Create'
    },
  },
  methods: {
    async saveChanges(bvModalEvt: BvModalEvent) {
      bvModalEvt.preventDefault()

      // eslint-disable-next-line consistent-return
      return new Promise((resolve, reject) => {
        (this.$refs.mailEventRules as InstanceType<typeof ValidationObserver>).validate().then(success => {
          if (success) {
            if (this.emailEvent.id) {
              this.updateEmailByEvent()
            } else {
              this.createEmailByEvent()
            }
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    async updateEmailByEvent() {
      await appSettingsCommunication.actions.updateEmailByEvent()
      this.hideModal()
    },
    async createEmailByEvent() {
      await appSettingsCommunication.actions.createEmailByEvent()
      this.hideModal()
    },
    hideModal() {
      this.$emit('updateTable')
      this.$bvModal.hide('email-event-modal')
    },
    resetEmailEvent() {
      appSettingsCommunication.mutations.RESET_EMAIL_EVENT()
    },
  },
})
