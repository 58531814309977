import axios from '@axios'

export const fetchCommunicationSettingsList = async (params: any): Promise<any> => {
  const res = await axios.get('/auth/mail-by-events', { params })
  return res
}

export const fetchEventsList = async (params?: any): Promise<any> => {
  const res = await axios.get('/auth/user-events', { params })
  return res
}

export const fetchSettings = async (params?: any): Promise<any> => {
  const res = await axios.get('/auth/settings', { params })
  return res
}

export const createEmailByEvent = async (params: any): Promise<any> => {
  const res = await axios.post('/auth/mail-by-events', params)
  return res
}

export const updateEmailByEvent = async (params: any): Promise<any> => {
  const res = await axios.put(`/auth/mail-by-events/${params.id}`, params)
  return res
}

export const deleteEmailByEvent = async (id: string): Promise<any> => {
  const res = await axios.delete(`auth/mail-by-events/${id}`)
  return res
}

export const postSetting = async (params: any): Promise<any> => {
  const res = await axios.post('/auth/settings', params)
  return res
}

export const updateSetting = async (params: any): Promise<any> => {
  const res = await axios.put(`/auth/settings/${params.id}`, params)
  return res
}
