import Vue from 'vue'
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { createModule } from 'vuexok'
import router from '@/router'
import store from '@/store'
import { IEmailByEventsListRequest, SettingsCommunicationState } from './types'
import { fetchEmailTemplatesList } from '@/api/emailTemplates'
import {
  createEmailByEvent, deleteEmailByEvent, fetchCommunicationSettingsList, fetchEventsList, fetchSettings, postSetting, updateEmailByEvent, updateSetting,
} from '@/api/emailByEvents'
import { fetchRolesList } from '@/api/roles'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'
import setPerPage from '@/helpers/setPerPage'

// eslint-disable-next-line import/prefer-default-export
export const appSettingsCommunication = createModule(
  'appSettingsCommunication',
  {
    namespaced: true,
    state: {
      tableColumns: [
        { key: 'name', label: 'Event', sortable: true },
        { key: 'receiver', label: 'To Role', sortable: true },
        { key: 'template', label: 'Email Template', sortable: false },
        { key: 'actions' },
      ],
      perPage: Number(localStorage.getItem('communicationPerPage')) || DEFAULT_PER_PAGE,
      totalItems: 0,
      currentPage: 1,
      perPageOptions: PER_PAGE_OPTIONS,
      searchQuery: '',
      sortBy: 'created_at',
      isSortDirDesc: true,
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      emailEvent: {
        role_id: null,
        email_template_id: null,
        user_event_id: null,
      },
      emailTemplatesList: [],
      rolesList: [],
      eventsList: [],
      communicationSettings: {
        id: null,
        communication: {
          id: null,
          name: null,
          email: null,
        },
      },
    } as SettingsCommunicationState,
    getters: {
      getTableColumns: state => state.tableColumns,
      getPerPage: state => state.perPage,
      getTotalItems: state => state.totalItems,
      getCurrentPage: state => state.currentPage,
      getPerPageOptions: state => state.perPageOptions,
      getSearchQuery: state => state.searchQuery,
      getSortBy: state => state.sortBy,
      getIsSortDirDesc: state => state.isSortDirDesc,
      getDataMeta: state => state.dataMeta,
      getEmailEvent: state => state.emailEvent,
      getEmailTemplatesList: state => state.emailTemplatesList,
      getRolesList: state => state.rolesList,
      getEventsList: state => state.eventsList,
      getCommunicationSettings: state => state.communicationSettings,
    },
    mutations: {
      SET_PER_PAGE(state, val: number) {
        localStorage.setItem('emailsPerPage', val.toString())
        state.perPage = val
      },
      SET_TOTAL_ITEMS(state, val: number) {
        state.totalItems = val
      },
      SET_CURRENT_PAGE(state, val: number) {
        state.currentPage = val
      },
      SET_SEARCH_QUERY(state, val: string) {
        state.searchQuery = val
      },
      SET_SORT_BY(state, val: string) {
        state.sortBy = val
      },
      SET_IS_SORT_DESC(state, val: boolean) {
        state.isSortDirDesc = val
      },
      SET_DATA_META(state, val) {
        state.dataMeta = val
      },
      SET_EMAIL_EVENT(state, val) {
        state.emailEvent = val
      },
      RESET_EMAIL_EVENT(state) {
        const emailEvent = {
          role_id: null,
          email_template_id: null,
          user_event_id: null,
        }
        state.emailEvent = emailEvent
      },
      SET_EMAIL_TEMPLATES_LIST(state, val) {
        state.emailTemplatesList = val
      },
      SET_ROLES_LIST(state, val) {
        state.rolesList = val
      },
      SET_EVENTS_LIST(state, val) {
        state.eventsList = val
      },
      SET_COMMUNICATION_SETTINGS(state, val) {
        state.communicationSettings = val
      },
      SET_FILTER_PARAMS(state) {
        const query = { ...router.currentRoute.query }
        const defaultPerPage = localStorage.getItem('communicationPerPage')
        state.currentPage = Number(query.currentPage) || 1
        state.perPage = setPerPage(query.perPage, defaultPerPage, DEFAULT_PER_PAGE)
        state.searchQuery = query.searchQuery || ''
      },
    },
    actions: {
      async fetchMailByEvents({ state }) {
        try {
          const queryParams: IEmailByEventsListRequest = {
            perPage: state.perPage,
            page: state.currentPage || 1,
            without_program: true,
          }
          if (state.searchQuery) queryParams.event = state.searchQuery
          const response:any = await fetchCommunicationSettingsList(queryParams)

          const {
            total, from, to,
          } = response.data.meta

          const dataMeta = {
            from,
            to,
            of: total,
          }

          appSettingsCommunication.mutations.SET_TOTAL_ITEMS(total)
          appSettingsCommunication.mutations.SET_DATA_META(dataMeta)
          return response.data.data
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching mail by events list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return []
        }
      },
      async fetchRolesList() {
        try {
          const rolesList = await fetchRolesList()
            // eslint-disable-next-line camelcase
            .then(response => response.data.data.reduce((arr:object[], item:{ id: number, display_name: string}) => {
              arr.push({ value: item.id, text: item.display_name })
              return arr
            }, [{ value: null, text: 'All' }]))

          appSettingsCommunication.mutations.SET_ROLES_LIST(rolesList)

          return rolesList
        } catch (error) {
          return error
        }
      },
      async fetchEventsList() {
        try {
          const eventsList = await fetchEventsList()
            // eslint-disable-next-line camelcase
            .then(response => response.data.data.reduce((arr: object[], item: { id: string | number, name: string}) => {
              arr.push({ value: item.id, text: item.name })
              return arr
            }, [{ value: null, text: 'Select User Event' }]))

          appSettingsCommunication.mutations.SET_EVENTS_LIST(eventsList)

          return eventsList
        } catch (error) {
          return error
        }
      },
      async fetchEmailTemplatesList() {
        try {
          const emailTemplatesList = await fetchEmailTemplatesList()
            // eslint-disable-next-line camelcase
            .then(response => response.data.data.reduce((arr: object[], item: { id: string | number, name: string}) => {
              arr.push({ value: item.id, text: item.name })
              return arr
            }, [{ value: null, text: 'Select Email Template' }]))

          appSettingsCommunication.mutations.SET_EMAIL_TEMPLATES_LIST(emailTemplatesList)

          return emailTemplatesList
        } catch (error) {
          return error
        }
      },
      async prepareMailSettings() {
        try {
          const allSettings = await fetchSettings().then(response => response.data.data)

          const settings = {
            id: null,
            communication: {
              id: null,
              email: null,
              name: null,
            },
          }
          settings.id = allSettings?.id
          settings.communication.id = allSettings?.communication_settings_id
          settings.communication.email = allSettings?.communication_from_email
          settings.communication.name = allSettings?.communication_from_name

          appSettingsCommunication.mutations.SET_COMMUNICATION_SETTINGS(settings)
          return settings
        } catch (error) {
          return error
        }
      },
      async createEmailByEvent({ state }) {
        try {
          const queryParams = state.emailEvent
          await createEmailByEvent(queryParams)
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error creating communication',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      },
      async updateEmailByEvent({ state }) {
        try {
          const queryParams = state.emailEvent
          await updateEmailByEvent(queryParams)
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating communication',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      },
      async deleteCommunicationItem(ctx, id) {
        try {
          await deleteEmailByEvent(id)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success deleting item',
              icon: 'Trash2Icon',
              variant: 'info',
            },
          })
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error deleting item',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      },
      async postSetting(ctx, preparedData) {
        try {
          const response = await postSetting(preparedData)
          const settings = {
            id: response?.id,
            communication: {
              id: response?.communication_settings_id,
              name: response?.communication_settings_from_name,
              email: response?.communication_settings_from_email,
            },
          }
          appSettingsCommunication.mutations.SET_COMMUNICATION_SETTINGS(settings)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Communications Settings were successfully created',
              icon: 'ThumbsUpIcon',
              variant: 'info',
            },
          })
          return response.data.data
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error creating mail settings',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return error
        }
      },
      async updateSetting(ctx, preparedData) {
        try {
          await updateSetting(preparedData)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Communications Settings were successfully updated',
              icon: 'ThumbsUpIcon',
              variant: 'info',
            },
          })
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating mail settings',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      },
    },
  },
)

appSettingsCommunication.register(store)
